<template>
  <div v-if="isLoggedIn == true" class="container">
    <div class="proccessing" v-if="loader">
      <p>Please don't refresh the page.</p>
      <img id="loader" src="../../assets/Circles-menu-3.gif" />
    </div>
    <div class="progress_sec" v-if="uploading && !success && !error">
      <div class="uploading_spinner">
        <ProgressSpinner />
      </div>
      <div class="progress_header">
        <div class="progress_status">
          Please wait, we are updating the data into your store.
        </div>
        <div v-if="uploadedCount > 0" class="total_uploaded_count">
          <span>
            Updated count: {{ uploadedCount }}
          </span> / <span> {{ totalDataCount }} Total count </span>

        </div>
      </div>
      <ProgressBar mode="determinate" style="height: 24px" :value="currentUploadCount"></ProgressBar>
      <div class="progress_status bottom">
        Please don't refresh the page until the process is complete.
      </div>
    </div>
    <div v-if="!success" class="inner_upload">
      <div class="error" v-if="error">
        <Message severity="error" @close="errorClose" :sticky="true">{{ error }}</Message>
      </div>
      <div v-if="!jsonData.length && !files">
        <FileUpload ref="fileUpload" v-model="uploadFile" name="demo[]" :disabled="error != ''" accept=".csv"
          @select="uploadHandlerCustom">
          <template #header="{ chooseCallback }">
            <div class="flex flex-wrap justify-content-between align-items-left flex-1 gap-2 width-48 a-l">
              <div class="flex gap-2">
                <Button id="uploader" @click="chooseCallback()" icon="pi pi-cloud-upload" label="Upload"
                  class="p-button-rounded"></Button>
              </div>
            </div>
          </template>
          <template #empty>
            <div @click="openUpload()" class="flex align-items-center justify-content-center flex-column">
              <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" />
              <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
              <p class="mt-4 mb-0 notices">Please upload a CSV file only.</p>
            </div>
          </template>
        </FileUpload>
      </div>
      <div v-if="chunkCheck && !error && files && !uploading" class="uploadBtn">
        <div class="uploaded_header">
          <h3><i class="pi pi-file"></i>
            {{ files.name }}</h3>
          <Button @click="clearCallback()" icon="pi pi-times" label="Remove"
            class="removebtn p-button-rounded p-button-danger"></Button>
          <Button @click="uploadFileToStore()" label="Update Records" icon="pi pi-upload"
            class="upload_btn p-button-rounded p-button-success p-right"></Button>
        </div>
      </div>
    </div>
    <div v-if="success && !uploading" class="success_box">
      <h3>Delivery dates have been successfully updated.</h3>
      <div class="success_btns">
        <a href="/upload" class="btn success-btn">Back</a>
        <a href="/vendors" class="btn success-btn">vendors</a>
      </div>
      <div v-if="errorVariants.length > 0" class="proccess_error">
        <div class="error_en">
          <p>Some of the dates are not updated please check the below entries and try to re update it.</p>
          <Button icon="pi pi-external-link" class="error_btn" label="Export to CSV" @click="exportErrorData"></Button>
        </div>
      </div>

    </div>
    <div v-bind:class="(loader) ? 'loading' : 'loaded'"></div>
  </div>
  <div v-else class="error">
    Please login to check the page <a href="/">Login</a>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';
import Message from 'primevue/message';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Papa from 'papaparse';
const csv = require("csvtojson")


export default {
  name: 'uploadsFile',
  components: {
    ProgressBar,
    FileUpload,
    Button,
    ProgressSpinner,
    Message
  },
  data() {
    return {
      loader: false,
      uploadFile: null,
      files: null,
      jsonData: [],
      errorVariants: [],
      uploading: false,
      success: false,
      error: '',
      uploadedCount: 0,
      totalDataCount: 0,
      currentUploadCount: 0,
      chunkCheck: false,
      chunkData: [],
      chunkSize: 50
    }
  },
  created() {
    this.columns = [
      { field: 'product_id', header: 'Product ID' },
      { field: 'title', header: 'Title' },
      { field: 'variant_id', header: 'Variant ID' },
      { field: 'sku', header: 'Variant Sku' },
      { field: 'inventoryquantity', header: 'Ineventory' },
      { field: 'metafield_id', header: 'Metafield Id' },
      { field: 'metafield_value', header: 'Metafield value' }
    ];
  },
  methods: {
    ...mapActions(["bulkUpdateMetafield"]),
    ...mapGetters(["isAuthenticated"]),
    onRemoveTemplatingFile(file, onFileRemove, index) {
      onFileRemove(index);
      this.totalSize -= parseInt(this.formatSize(file.size));
      this.totalSizePercent = this.totalSize / 10;
      this.files = null;
      this.jsonData = [];
    },
    openUpload() {
      document.getElementById("uploader").click();
    },
    async uploadHandlerCustom(event) {
      const file = event.files[0];
      this.files = file;
      const csvString = await file.text();
      const lines = csvString.trim().split("\n");
      var headers = lines[0].split(",");
      headers = headers.map(field => field.replace('\r', ''));
      if (headers.indexOf("variantID") == -1 || headers.indexOf("metafieldID") == -1 || headers.indexOf("metafieldValue") == -1) { return this.error = "Error: CSV data is invalid. Please verify and correct the data before proceeding."; }
      this.loader = true;
      const uploadedData = await this.csvStringToJson(csvString);
      if (!uploadedData) { return this.error = "Error: CSV data is invalid. Please verify and correct the data before proceeding."; }
      this.jsonData = uploadedData;
      if (this.jsonData.length > this.chunkSize) {
        await this.createRequestes();
      } else {
        this.chunkCheck = true;
        this.chunkData = this.jsonData;
        this.totalDataCount = this.jsonData.length;
      }
      this.loader = false;
      console.log("this.chunkData", this.chunkData);
    },
    async createRequestes() {
      this.totalDataCount = this.jsonData.length;
      if (this.jsonData) {
        for (let i = 0; i < this.jsonData.length; i += this.chunkSize) {
          const chunk = this.jsonData.slice(i, i + this.chunkSize);
          this.chunkData.push(chunk);
        }
      }
      this.chunkCheck = true;
      this.loader = false
    },
    async clearCallback() {
      this.files = null;
      this.jsonData = [];
      this.uploadedCount = 0;
      this.totalDataCount = 0;
      this.currentUploadCount = 0;
      this.chunkCheck = false;
      this.uploading = false;
      this.chunkData = [];
      this.error = '';
    },
    async uploadFileToStore() {
      this.uploading = true;
      var uploadedLength = 0;
      if (this.totalDataCount > this.chunkData.length) {
        for (let index = 0; index < this.chunkData.length; index++) {
          const element = this.chunkData[index];
          let upload = { "data": element, "token": this.userToken };
          var result = await this.bulkUpdateMetafield(upload);
          if (result.status == 200) {
            this.errorVariants = this.errorVariants.concat(result.data.errors);
            uploadedLength += element.length;
            this.uploadedCount = uploadedLength;
            this.currentUploadCount = Math.ceil((100 * uploadedLength) / this.totalDataCount);
            if (uploadedLength == this.totalDataCount) {
              this.success = true;
              this.uploading = false;
            }
          } else {
            return this.error = "Error: CSV data is invalid. Please verify and correct the data before proceeding.";
          }
        }
      } else {
        let lessData = { "data": this.chunkData, "token": this.userToken };
        var data = await this.bulkUpdateMetafield(lessData);
        if (data.status == 200) {
          this.errorVariants = this.errorVariants.concat(data.data.errors);
          this.success = true;
          this.uploading = false;
        }
      }

    },
    async exportErrorData() {
      const header = ['name', 'productID', 'sku', "variantID", "metafieldID", "metafieldValue"];
      const csvData = Papa.unparse({ data: this.errorVariants, fields: header });
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'errorData.csv');
      document.body.appendChild(link);
      link.click();
    },

    csvStringToJson(csvString) {
      return new Promise((resolve, reject) => {
        csv({
          noheader: false,
          output: "json",
          ignoreEmpty: true
        }).fromString(csvString)
          .then(jsonObj => {
            resolve(jsonObj);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    formatSize(bytes) {
      if (bytes === 0) {
        return '0 B';
      }

      let k = 1000,
        dm = 3,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    errorClose() {
      this.files = null;
      this.jsonData = [];
      this.uploadedCount = 0;
      this.totalDataCount = 0;
      this.currentUploadCount = 0;
      this.chunkCheck = false;
      this.chunkData = [];
      this.uploading = false;
      this.error = '';
    }
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated
    },
    userToken: function () {
      return this.$store.getters.getUserToken
    },
  }
}
</script>